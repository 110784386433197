import { css } from "@emotion/react";
import LinkifyIt, { Match } from "linkify-it";
import React from "react";
import Linkify from "react-linkify";
import tlds from "tlds";

import { Link, LinkComponent } from "src/components/Link";
import { LinkAppearance } from "src/styles/link";
import { FontWeight } from "src/theme/text";

const linkify = new LinkifyIt();
linkify.tlds(tlds);

export default function LinkifyImpl({
  original,
  text,
  target,
}: {
  original: string;
  text: string;
  target?: string;
}) {
  return (
    <Linkify
      componentDecorator={getLinkDecorator(
        Link,
        linkify.match(original) || undefined,
        target
      )}
    >
      {text}
    </Linkify>
  );
}

function getLinkDecorator(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Link: LinkComponent,
  match?: Match[],
  target?: string
) {
  function linkDecorator(
    decoratedHref: string,
    decoratedText: string,
    key: number
  ): React.ReactNode {
    return (
      <Link
        key={key}
        css={css`
          display: inline;
          font-weight: ${FontWeight.REGULAR};
        `}
        data-tname="Linkify--Link"
        rel="noopener nofollow ugc"
        to={(match && match[key].url) || decoratedHref}
        appearance={LinkAppearance.HYPERLINK}
        target={target}
      >
        {decoratedText}
      </Link>
    );
  }

  return linkDecorator;
}
